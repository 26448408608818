// 轮播，列表数据
export const GetfrList = '/frDataJson/GetfrList.json';
// 教程，关于数据
export const GetfrDoc = '/frDataJson/GetfrDoc.json';
// 广告，公告，用户信息数据
export const GetfrBasic = '/frDataJson/GetfrBasic.json';


// 轮播，列表数据
// export const GetfrList = 'http://rap2api.taobao.org/app/mock/data/2145146';
// 教程，关于数据
// export const GetfrDoc = 'http://rap2api.taobao.org/app/mock/data/2151098';
// 广告，公告，用户信息数据
// export const GetfrBasic = 'http://rap2api.taobao.org/app/mock/data/2145247';


// 本程序SVG来自
// <a href="https://storyset.com/coronavirus">Coronavirus illustrations by Storyset</a>