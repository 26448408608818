<template>
  <div>
    <!-- S logo -->
    <div class="logo">
      <img class="fengrui_img smMedia_img" src="../assets/imgs/frcover.png" />
    </div>
    <!-- E logo -->

    <!-- S 菜单主体 -->
    <el-menu
      router
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      active-text-color="#5E81F4"
      text-color="#A2A2C2"
      :collapse="isCollapse"
    >
      <el-menu-item
        v-for="(item, index) in $router.options.routes"
        :key="index"
        :index="item.path"
      >
        <i :class="item.icon"></i>
        <span slot="title">{{ item.menuName }}</span>
      </el-menu-item>
    </el-menu>
    <!-- E 菜单主体 -->

    <!-- S 底部广告 -->
    <div class="aside_ad" v-if="!isCollapse">
      <a :href="advertSid.ad_aside_url" target="_back">
        <el-image class="fengrui_img" :src="advertSid.ad_aside_img"> </el-image>
      </a>
    </div>
    <!-- E 底部广告 -->
  </div>
</template>
<style>
/* 默认参数 */
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 300px;
}
/* 左侧菜单 */
.logo {
  margin: 30px auto;
  width: 144px;
  height: 28px;
}

/* 菜单自定义 */
.menuitem {
  position: relative;
}

.el-menu-item {
  margin-left: 30px;
}

.el-menu {
  border: unset;
}

.is-active::after {
  content: "";
  position: absolute;
  right: 20px;
  top: 30%;
  height: 20px;
  width: 4px;
  border-radius: 20px;
  background-color: rgb(94, 129, 244);
}

.aside_ad {
  position: fixed;
  bottom: 66px;
  left: 50px;
  height: 194px;
  width: 194px;
  border-radius: 20px;
}

.el-image {
  border-radius: 12px;
}
/* css3媒体查询 */
@media (max-width: 760px) {
  .el-menu-item {
    margin-left: unset;
  }
  .is-active::after {
    display: none;
  }
  .logo{
    margin: 80px auto 40px auto;
    height:18px ;
    width: 68px;
    -webkit-transform:rotate(90deg);
  }
}
/* 深色模式 */
@media (prefers-color-scheme: dark) { 
  .el-aside,.el-menu { 
    background-color: #101927 !important;
  }
  .el-menu-item:focus, .el-menu-item:hover{
    background-color: #2f415e;
  }
}
</style>

<script>
// 引入组件
import axios from "axios";
import { GetfrBasic } from "./api";
export default {
  data() {
    return {
      // 展开/缩起开关
      isCollapse: true,
      // 广告数据
      advertSid: [],
    };
  },

  mounted() {
    // 请求数据
    this.frDate();
  },
  created() {
    // 判断分辨率
    var smMedia = window.matchMedia("(max-width:768px)");
    console.log(smMedia);
    this.isCollapse = smMedia.matches;
    // 页面标题
    document.title = "Fr Cover 免版权插画下载_枫瑞博客网";
  },
  methods: {
    // 获取数据
    frDate() {
      // 请求广告 用户社交信息
      axios
        .get(GetfrBasic)
        .then((res) => {
          this.advertSid = res.data.advert_sid;
          // console.log(this.advertSid);
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>